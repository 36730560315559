import React from "react";
import classNames from "classnames";
import { Container, Text, Button } from "@atoms";
import { AppLink } from "@base";

const Cta = ({ color, icon, heading, descriptor, button }) => {
  const background = {
    white: "bg-white",
    offWhite: "bg-offwhite",
    lighterTeal: "bg-teal-lighter",
    lightTeal: "bg-teal-light",
    teal: "bg-teal",
    darkTeal: "bg-teal-dark",
    black: "bg-black",
    pink: "bg-pink",
    purple: "bg-purple",
  }[color];

  const isDark =
    color === "pink" ||
    color === "purple" ||
    color === "teal" ||
    color === "darkTeal" ||
    color === "black";

  return (
    <Container>
      <AppLink to={button?.url}>
        <div
          className={classNames(
            "rounded-lg py-12 px-12 shadow-lg md:px-16 lg:px-24",
            background
          )}
        >
          <div className="flex flex-col items-center gap-8 text-center">
            {!!icon[0]?.code && (
              <div
                className="svg-icon flex h-auto w-28 items-center justify-center"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: icon[0]?.code }}
              />
            )}
            <div
              className={classNames("flex flex-col gap-4", {
                "text-white": isDark,
              })}
            >
              <Text variant="h3">{heading}</Text>
              <Text variant="sm">{descriptor}</Text>
            </div>
            {!!button?.url && (
              <Button color={color === "pink" ? "white" : "blackPink"}>
                {button?.text || "Learn more"}
              </Button>
            )}
          </div>
        </div>
      </AppLink>
    </Container>
  );
};

Cta.defaultProps = {};

export default Cta;
